<template>
    <div>
        <div class="mb-4">
            <select v-model="selectedMonth" class="input">
                <option v-for="(option, index) in months" :key="index" :value="option">
                    {{ option.dateLabel }}
                </option>
                </select>
        </div>
        
        <div class="notification is-warning" v-if="loading == true">
            Loading bookings ...
        </div>
        <div class="notification is-danger" v-if="loading == false  && bookings.length == 0">
            No bookings can be found matching your search criteria
        </div>

        <div v-if="loading==false && bookings.length > 0">
            <div class="columns">
                <div class="column">
                    <div class="box has-background-success has-text-white" style="cursor:pointer" @click="showBookingsPopup('Filled Shifts', confirmedBookings)">Filled Shifts : {{ confirmedBookings.length }}</div> 
                </div>
                <div class="column">
                    <div class="box has-background-warning has-text-black" style="cursor:pointer" @click="showBookingsPopup('Missed Shifts', missedBookings)">Missed Shifts : {{ missedBookings.length }}</div> 
                </div>
                <div class="column">
                    <div class="box has-background-link has-text-white" style="cursor:pointer" @click="showBookingsPopup('All Cancelled Shifts', cancelledBookings)">Cancelled Shifts : {{ cancelledBookings.length }}</div> 
                </div>
            </div>

            
            <h4 class="title is-size-4">Filled Summary</h4>
            <table class="table is-fullwidth mb-6">
                <thead>
                    <tr>
                        <th width="50%">Grade</th>
                        <th>Count</th>
                    </tr>
                </thead>
                <tr v-for="row in uniqueGradesConfirmed" :key="row.grade">
                    <td>{{  row.grade }}</td>
                    <td>{{ row.total}}</td>
                </tr>
            </table>

            <h4 class="title is-size-4">Missed Summary</h4>
            <table class="table is-fullwidth mb-6">
                <thead>
                    <tr>
                        <th width="50%">Grade</th>
                        <th>Count</th>
                    </tr>
                </thead>
                <tr v-for="row in uniqueGradesMissed" :key="row.grade">
                    <td>{{  row.grade }}</td>
                    <td>{{ row.total}}</td>
                </tr>
            </table>

            <h4 class="title is-size-4">Cancelled Summary</h4>
            <table class="table is-fullwidth mb-6">
                <thead>
                    <tr>
                        <th width="50%">Grade</th>
                        <th>Count</th>
                    </tr>
                </thead>
                <tr v-for="row in uniqueGradesCancelled" :key="row.grade">
                    <td>{{  row.grade }}</td>
                    <td>{{ row.total}}</td>
                </tr>
            </table>

            <h4 class="title is-size-4">Cancelled Bookings Breakdown</h4>
            <table class="table is-fullwidth mb-6">
                
                <tr @click="showBookingsPopup('Cancelled by Client', cancelledByClientBookings)" style="cursor: pointer;">
                    <td>Cancelled By Client</td>
                    <td>{{ this.cancelledByClientBookings.length }}</td>
                </tr>
                <tr @click="showBookingsPopup('Cancelled by Member', cancelledByMemberBookings)" style="cursor: pointer;">
                    <td>Cancelled By Member</td>
                    <td>{{ this.cancelledByMemberBookings.length }}</td>
                </tr>
                <tr @click="showBookingsPopup('Cancelled by Balmoral', cancelledByBalmoralBookings)" style="cursor: pointer;">
                    <td>Cancelled By Balmoral</td>
                    <td >{{ this.cancelledByBalmoralBookings.length }}</td>
                </tr>
            </table>
            

           
        </div>

        <div class="modal" :class="{'is-active': showBookingsModal==true}" >
            <div class="modal-background"></div>
            <div class="modal-card" style="width:1320px">
                <header class="modal-card-head">
                    <p class="modal-card-title">{{ modalHeading }}</p>
                    <button class="delete" aria-label="close" @click="closeModal()"></button>
                </header>
                <section class="modal-card-body">
                    <table class="table is-striped is-hoverable is-fullwidth" v-if="loading==false">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Member</th>
                                <th>Client</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="row in modalBookings" :key="row.id">
                                <td>{{  row.id }}</td>
                                <td>{{ row.member_forename }} {{ row.member_surname }}</td>
                                <td>{{ row.client_name}}</td>
                                <td>{{ row.start_date}}</td>
                            </tr>
                        </tbody>
                    </table>
                </section>
                <footer class="modal-card-foot">
                    <button class="button" @click="closeModal()">Close</button>
                </footer>
            </div>
        </div>

        
    </div>
</template>

<script>
import UtilityService from '@/services/UtilityService.js';
//import BookingService from '@/services/BookingService.js';
import MemberMixin from '@/mixins/MemberMixin.js';



export default {
    
      name: 'MonthlyStats',
        mixins: [MemberMixin],
        data: function () {
            return {
                   search: '',
                   bookings: [],
                   loading: false,
                   working:false,
                   showModal: false,
                   assigned:false,
                   alertText: '',
                   members: [],
                   booking_id: '',
                   showCancelModal: false,
                   selectedMonth: { 
                   
                    },
                   months: [
                    ],
                    accessToken: '',
                    showBookingsModal: false,
                    modalBookings: [],
                    modalHeading: 'Loading ...'
            }
        },
        components: {
            
        },
        methods: {
            closeModal() {
                this.modalBookings = []
                this.showBookingsModal = false 
            },

            showBookingsPopup(modalLabel, bookingsToShow)
            {
                this.modalHeading = modalLabel
                this.modalBookings = bookingsToShow
                this.showBookingsModal = true
            },  
             async getBookings() {
                if(this.selectedMonth.startDate != undefined)
                {
                    this.loading=true
                    UtilityService.getSimpleApiData(this.accessToken, "bookings?start_date=" + this.selectedMonth.startDate + "&end_date=" + this.selectedMonth.endDate )
                    .then(
                        (bookings => {
                            this.$set(this, "bookings", bookings);
                            this.loading=false;
                            this.working = false;
                        }).bind(this)
                    );
                }
            },
            async getMonths() {
                 UtilityService.getSimpleApiData(this.accessToken, "stats_months" )
                .then(
                    (months => {
                        this.$set(this, "months", months);
                    }).bind(this)
                );
            },
            async getCurrentMonth() {
                 UtilityService.getSimpleApiData(this.accessToken, "stats_months?current_month=1" )
                .then(
                    (selectedMonth => {
                        this.$set(this, "selectedMonth", selectedMonth);
                    }).bind(this)
                );
            },
            
           
            
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getMonths();
                this.getCurrentMonth();

                this.getBookings();
            });
        
        },
        computed: {
            confirmedBookings() {
                return this.bookings.filter(item => item.booking_status_id === 4);
            },
            missedBookings() {
                return this.bookings.filter(item => item.booking_status_id === 1 || item.booking_status_id === 2 || item.booking_status_id === 3 || item.booking_status_id === 5);
            },
            cancelledBookings() {
                return this.bookings.filter(item => item.booking_status_id === 6 || item.booking_status_id === 7 || item.booking_status_id === 8);
            },
            cancelledByClientBookings() {
                return this.bookings.filter(item => item.booking_status_id === 6 );
            },
            cancelledByMemberBookings() {
                return this.bookings.filter(item => item.booking_status_id === 7 );
            },
            cancelledByBalmoralBookings() {
                return this.bookings.filter(item => item.booking_status_id === 8);
            },
            
            uniqueGradesConfirmed() {
                const gradeCounts = {};
                
                // Count the occurrences of each grade
                this.confirmedBookings.forEach(item => {
                    const grade = item.grade_code;
                    if (gradeCounts[grade]) {
                    gradeCounts[grade]++;
                    } else {
                    gradeCounts[grade] = 1;
                    }
                });
                
                // Transform the counts into an array of objects
                const uniqueGrades = [];
                for (const grade in gradeCounts) {
                    uniqueGrades.push({
                    grade: grade,
                    total: gradeCounts[grade]
                    });
                }
                
                return uniqueGrades;
            },
            uniqueGradesCancelled() {
                const gradeCounts = {};
                
                // Count the occurrences of each grade
                this.cancelledBookings.forEach(item => {
                    const grade = item.grade_code;
                    if (gradeCounts[grade]) {
                    gradeCounts[grade]++;
                    } else {
                    gradeCounts[grade] = 1;
                    }
                });
                
                // Transform the counts into an array of objects
                const uniqueGrades = [];
                for (const grade in gradeCounts) {
                    uniqueGrades.push({
                    grade: grade,
                    total: gradeCounts[grade]
                    });
                }
                
                return uniqueGrades;
            },
            uniqueGradesMissed() {
                const gradeCounts = {};
                
                // Count the occurrences of each grade
                this.missedBookings.forEach(item => {
                    const grade = item.grade_code;
                    if (gradeCounts[grade]) {
                    gradeCounts[grade]++;
                    } else {
                    gradeCounts[grade] = 1;
                    }
                });
                
                // Transform the counts into an array of objects
                const uniqueGrades = [];
                for (const grade in gradeCounts) {
                    uniqueGrades.push({
                    grade: grade,
                    total: gradeCounts[grade]
                    });
                }
                
                return uniqueGrades;
            }
            
        },
        watch: {
            selectedMonth: function(){
                this.getBookings();
            },
            
        }
    }
</script>