<template>
    <div class="modal " :class="{'is-active': showEmailModal==true}">
        <div class="modal-background"></div>
        <div class="modal-card"  style="width:840px">
            <header class="modal-card-head">
                <p class="modal-card-title">Compose Email Text</p>
                <button class="delete" aria-label="close" @click="closeModal()"></button>
            </header>
            <section class="modal-card-body" >
                <form>
                    <div class="field">
                <textarea v-model="emailText" class="textarea"></textarea>
            </div>
                <button @click="copyToClipboard" class="button is-success" type="button">Copy to Clipboard</button>
            </form>
            </section>
            
            <footer class="modal-card-foot" >
            <button class="button" @click="closeModal()" :disabled="working==true">Close</button>
            </footer>
        </div>
        
       
    </div>
</template>

<script>
//import UtilityService from '@/services/UtilityService.js';
//import BookingService from '@/services/BookingService.js';
import MemberMixin from '@/mixins/MemberMixin.js';

//import MemberGradeTags from '@/components/Generic/MemberGradeTags.vue';

export default {
    
      name: 'NoteModal',
        mixins: [MemberMixin],
        components: {
            
        },
        props: ['bookings', 'showEmailModal'],
        data: function () {
            return {
                   search: '',
                   booking: [],
                   searchPerformed: false,
                   assigned:false,
                   alertText: '',
                   working:false,
                   loading: true,
                   reason: '',
                   booking_status_id: '',
                   
            }
        },
        methods: {
            
            
            async closeModal() {
                
                
                this.$emit('close')
            },
            copyToClipboard() {
            navigator.clipboard.writeText(this.emailText)
                .then(() => {
                alert("Copied to clipboard!");
                })
                .catch(err => {
                console.error("Failed to copy: ", err);
                });
            }
            
        },
        computed : {
            emailText() {
                return Object.values(this.bookings)
                .map(booking => 
                    booking.ward_name 
                        ? `${booking.client_name} (${booking.ward_name}) - ${booking.grade_code} - ${booking.formatted_start_date_time} - ${booking.forename} ${booking.surname}`
                        : `${booking.client_name} - ${booking.grade_code} - ${booking.formatted_start_date_time} - ${booking.forename} ${booking.surname}`
                )
                    .join("\n");
                }
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                
            });
        
        },
        watch: {
            
           
        },
    }
</script>